<template>
	<div class="appro-box page-container">
	   <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>  
		<div class="page-desc">
		  待处理：需要我审批的所有单据，可点击单据后边的【审批】按钮进行审批<br />
		  
		  <template v-if="uId < 1">
			  已处理：我作为审批人审批过的所有单据
			  我发起的：我发起的所有审批单据
		  </template>
		 
		 </div>   
		   <el-tabs v-model="active" @tab-click="handleClick">
			 <el-tab-pane label="待处理" name="0"></el-tab-pane>
			 <el-tab-pane label="已处理" name="1" v-if="uId < 1"></el-tab-pane>
			 <el-tab-pane label="我发起的" name="2" v-if="uId < 1"></el-tab-pane>
		   </el-tabs>
		
		<div class="appro-info">
			<el-empty :image-size="150" v-if="approList.length <1"></el-empty>
			<el-row style="border-bottom: 1px solid #C0C4CC;" v-for="item in approList">
				<el-col :span="24">
					<div class="grid-content  bg-purple-dark">
						<!-- <span style="width: 2px;height: 10px;background-color: #409EFF;"></span> -->
						<span  style="margin-left: 10px;font-size: 18px;color: #303133;font-weight: bold;">{{item.title}}</span> 
					</div>   
				</el-col>
				<template v-for="(child,childIndex) in item.items">
					<template v-if="childIndex == 3 ">
						<el-col :span="6">
							<div class="grid-content  bg-purple-dark">
								  <el-button  size="mini" @click="handleShowApproval(item.storage_id,item.storage_type,item.title)">审批</el-button>
							</div>
						</el-col>
					</template>
					<el-col :span="6" >
						<div class="grid-content  bg-purple-dark">
							<span class="title">{{child.title}}:{{child.value}}</span>
						</div>
					</el-col>
					<template v-if=" item.items.length <= 3 && childIndex == item.items.length-1">
						<el-col :span="6">
							<div class="grid-content  bg-purple-dark">
								  <el-button  size="mini" @click="handleShowApproval(item.storage_id,item.storage_type,item.title)">审批</el-button>
							</div>
						</el-col>
					</template>
				</template>
			</el-row>
		</div>	
		 <ApprovalVue ref="approval" ></ApprovalVue> 
	</div>
</template>

<script>
import ApprovalVue from '../Common/ApprovalDrawer.vue'

	export default {
		 props:['userId'],
		components: {
			ApprovalVue
		},
		inject:['reload'],
		data() {
			return {
				active:0,
				approList:[],
				uId:this.userId??0 
			}
		},
		created() {
			this.onLoad()
		},
		mounted() {

		},
	 methods: {
			onLoad() {
				this.$api.post('AssetApproval/index',{
					type:this.active,
					user_id:this.uId
				},res=>{
					if(res.code == 200){
						this.approList = res.data.list
					}
				})	
			},
			handleClick(){
				this.onLoad()
			},
			handleShowApproval(id,type,card_name){
				this.$refs.approval.approvelType  = type
				this.$refs.approval.handleApproval(id,type,card_name)	
			}
		}, 
	}
</script>
<style scoped lang="scss">
	.appro-box {
		font-size: 14px;
		.appro-info {
			
			.title{
				color: #606266;
			}
			.el-row{
				.el-col{
					height: 40px;
					line-height: 40px;
				}
			}
			
		}
	}
</style>
